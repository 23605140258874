import React, { useContext, useEffect, useRef, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FormTotal from './FormTotal'
import { AutoContext } from '../../contexts/AutoContext'
import { DateInput } from '../base/DateInput'
import SelectSwitch from '../common/SelectSwitch'
import Input from '../base/Input'
import InputMask from '../base/InputMask'
import Checkbox from '../base/Checkbox'
import { docTypes, gender } from '../../helpers/travel'
import Select from '../base/Select'
import citizenship from '../../utils/citizenship.json'

// const colors = [{ value: 'black', label: 'black' }, { value: 'white', label: 'white' }]

export default function FormStep3() {
    const { setShortValidate, canCopy, setCanCopy, isEci, debouceHandleSubmit } = useContext(AutoContext)

    const { t } = useTranslation('auto')
    const { values, errors, setFieldValue, validateForm } = useFormikContext()
    const timer = useRef()
    const itemsRef = useRef({})
    const [documentTypes] = useState(docTypes.filter((item) => item.value !== '1'))

    const minDate = (date, i) => moment((i >= 0 ? values.driver[i][date] : values[date]) || moment()).add(1, 'year').format('YYYY-MM-DD')

    useEffect(() => validateForm(), [])
    useEffect(() => {
        if (values.driver.length === 0) {
            const data = [...values.driver]
            data.push({
                driverFullName: '',
                driverLastname: '',
                driverFirstname: '',
                driverPatr: '',
                docSeries: '',
                docNumber: '',
                docIssueDate: '',
                driverBd: '',
                driverGender: '',
                driverCitizenship: 'TJ',
            })
            setFieldValue('driver', data)
        }
    })

    // const duration = useMemo(() => [{ value: 'monthly', label: t('monthly') }, { value: 'annually', label: t('annually') }], [])

    return (
        <section className="step step-3">
            <div className="travel-one__form-items">
                <div className="travel-one__form-item">
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">{t('insured')}</h3>
                        <span className="auto__subtitle-decor" />
                    </div>
                    <div className="travel-one__form-row">
                        <Input label={t('holderFullName')}
                            name="holderFullName"
                            refLinlk={(el) => { itemsRef.current.holderFullName = el }}
                            placeholder={t('holderFullNamePH')}
                            error={errors.holderFullName || errors.holderLastname || errors.holderFirstname}
                            onChange={(e) => {
                                clearTimeout(timer.current)
                                setTimeout(() => {
                                    const str = e.target.value.replace(/[ ]+/gm, ' ')
                                    const value = str.split(' ')
                                    setFieldValue('holderLastname', value[0] || '')
                                        .then(() => setFieldValue('holderFirstname', value[1] || ''))
                                        .then(() => {
                                            value.splice(0, 2)
                                            return setFieldValue('holderPatronymic', (value && value.join(' ')) || '')
                                        })
                                }, 300)
                            }}
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <DateInput label={t('holderBirthday')}
                            name="holderBirthday"
                            refLink={(el) => { itemsRef.current.holderBirthday = el }}
                            error={errors.holderBirthday}
                            onChange={(e) => {
                                setFieldValue('holderBirthday', e.target.value)
                                debouceHandleSubmit()
                            }}
                            className="input travel-order__input-date input__required auto__input"
                            min={moment().add(-100, 'year').format('YYYY-MM-DD')}
                            max={moment().add(-18, 'year').format('YYYY-MM-DD')}
                        />
                        <SelectSwitch options={gender} name="holderGender" label={t('holderGender')}
                            refLinlk={(el) => { itemsRef.current.holderGender = el }}
                            className="select select__travel-order input__required" value={values.holderGender}
                            onChange={(value) => setFieldValue('holderGender', value)}
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <Select
                            label={t('holderDocType')}
                            name="holderDocType"
                            error={errors.holderDocType}
                            options={documentTypes}
                            defValue="--------"
                            inputRef={(el) => { itemsRef.current.holderDocType = el }}
                            onChange={(value) => {
                                setFieldValue('holderDocType', value)
                            }}
                            className="select auto__input"
                        />
                        <DateInput
                            name="holderIssueDate"
                            error={errors.holderIssueDate}
                            max={moment().format('YYYY-MM-DD')}
                            min={minDate('holderBirthday')}
                            className="input travel-order__input-date input__required auto__input"
                            label={t('holderIssueDate')}
                            refLink={(el) => { itemsRef.current.holderIssueDate = el }}
                            onChange={() => window.setTimeout(() => {
                                setShortValidate(true)
                                debouceHandleSubmit()
                            }, 500)}
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <Input label={t('holderDocSeries')}
                            name="holderDocSeries"
                            error={errors.holderDocSeries}
                            placeholder="AA"
                            refLinlk={(el) => { itemsRef.current.holderDocSeries = el }}
                            divClass="input input__required auto__input"
                        />
                        <Input label={t('holderDocNumber')}
                            name="holderDocNumber"
                            error={errors.holderDocNumber}
                            refLinlk={(el) => { itemsRef.current.holderDocNumber = el }}
                            placeholder="123123123"
                            divClass="input input__required auto__input"
                        />
                    </div>
                    <div className="travel-one__form-row">
                        <Input label={t('holderAddress')}
                            name="holderAddress"
                            refLinlk={(el) => { itemsRef.current.holderAddress = el }}
                            placeholder={t('holderAddressPH')}
                            error={errors.holderAddress}
                        />
                    </div>
                    <div className="travel-order__row">
                        <InputMask label={t('holderPhone')}
                            name="holderPhone" placeholder="(992) 00-000-0000"
                            refLinlk={(el) => { itemsRef.current.holderPhone = el }}
                            error={errors.holderPhone}
                        />
                        <Input label={t('holderEmail')}
                            refLinlk={(el) => { itemsRef.current.holderEmail = el }}
                            name="holderEmail" placeholder="mail@mail.ru"
                            error={errors.holderEmail}
                        />
                    </div>
                    <div className="auto__subtitle-box">
                        <h3 className="auto__subtitle">Водители</h3>
                        <span className="auto__subtitle-decor" />
                        { isEci && (
                            <Checkbox
                                className="travel-order__text checkbox auto-rules__multi"
                                name="multiDriveOption"
                                error={errors.agree}
                                label={t('multidrive')}
                                onChange={() => {
                                    setShortValidate(true)
                                    debouceHandleSubmit()
                                }}
                            />
                        )}
                    </div>
                    {
                        (values.multiDriveOption === 0 || values.multiDriveOption === false) && values.driver.map((_, i) => (
                            <div className="driver-card__wrap">
                                {canCopy && (
                                    <a className="change__button auto__copy-button driver-button"
                                        onClick={async (e) => {
                                            e.preventDefault()
                                            if (canCopy) {
                                                const data = {
                                                    holderFullName: `driver[${i}]driverFullName`,
                                                    holderLastname: `driver[${i}]driverLastname`,
                                                    holderFirstname: `driver[${i}]driverFirstname`,
                                                    holderPatronymic: `driver[${i}]driverPatr`,
                                                    // holderDocSeries: `driver[${i}]docSeries`,
                                                    // holderDocNumber: `driver[${i}]docNumber`,
                                                    // holderIssueDate: `driver[${i}]docIssueDate`,
                                                    holderBirthday: `driver[${i}]driverBd`,
                                                    holderGender: `driver[${i}]driverGender`,
                                                }

                                                Object.keys(data).forEach((item) => {
                                                    setFieldValue(data[item], values[item])
                                                })

                                                setCanCopy(false)

                                                window.setTimeout(() => validateForm(), 500)
                                            }
                                        }}
                                    >
                                        {t('copyHolderData')}
                                    </a>
                                )}
                                <div className="travel-one__form-row">
                                    <Input label={t('holderFullName')}
                                        refLinlk={(el) => { itemsRef.current[`driver[${i}]driverFullName`] = el }}
                                        name={`driver[${i}]driverFullName`}
                                        placeholder={t('holderFullNamePH')}
                                        error={errors[`driver[${i}]driverFullName`] || errors[`driver[${i}]driverLastname`]
                                               || errors[`driver[${i}]driverFirstname`]}
                                        onChange={(e) => {
                                            clearTimeout(timer.current)
                                            setTimeout(() => {
                                                const str = e.target.value.replace(/[ ]+/gm, ' ')
                                                const value = str.split(' ')
                                                setFieldValue(`driver[${i}]driverLastname`, value[0] || '')
                                                    .then(() => setFieldValue(`driver[${i}]driverFirstname`, value[1] || ''))
                                                    .then(() => {
                                                        value.splice(0, 2)
                                                        return setFieldValue(`driver[${i}]driverPatr`, (value && value.join(' ')) || '')
                                                    })
                                            }, 300)
                                        }}
                                    />
                                </div>
                                <div className="travel-one__form-row">
                                    <DateInput label={t('holderBirthday')}
                                        name={`driver[${i}]driverBd`}
                                        min={moment().add(-100, 'year').format('YYYY-MM-DD')}
                                        max={moment().add(-18, 'year').format('YYYY-MM-DD')}
                                        refLink={(el) => { itemsRef.current[`driver[${i}]driverBd`] = el }}
                                        error={errors[`driver[${i}]driverBd`]}
                                        onChange={(e) => {
                                            setFieldValue(`las[${i}]driverBd`, e.target.value)
                                            debouceHandleSubmit()
                                        }}
                                        className="input travel-order__input-date input__required auto__input"
                                    />
                                    <SelectSwitch options={gender} name={`driver[${i}]driverGender`} label={t('holderGender')}
                                        refLinlk={(el) => { itemsRef.current[`driver[${i}]driverGender`] = el }}
                                        className="select select__travel-order input__required" value={values.driver[i].driverGender}
                                        onChange={(value) => setFieldValue(`driver[${i}]driverGender`, value)}
                                    />
                                </div>
                                <div className="travel-one__form-row">
                                    <Select
                                        name={`driver[${i}]driverCitizenship`}
                                        error={errors[`driver[${i}]driverCitizenship`]}
                                        className="select input input__required auto__input"
                                        options={citizenship} required filter
                                        inputRef={(el) => { itemsRef.current[`driver[${i}]driverCitizenship`] = el }}
                                        label={t('citizenship')} />
                                    <DateInput
                                        name={`driver[${i}]docIssueDate`}
                                        max={moment().add(-1, 'year').format('YYYY-MM-DD')}
                                        min={minDate('driverBd', i)}
                                        refLink={(el) => { itemsRef.current[`driver[${i}]docIssueDate`] = el }}
                                        className="input travel-order__input-date input__required auto__input"
                                        label={t('driverIssueDate')} val={values[`driver[${i}]docIssueDate`]}
                                        error={errors[`driver[${i}]docIssueDate`]}
                                        onChange={() => window.setTimeout(() => {
                                            setShortValidate(true)
                                            debouceHandleSubmit()
                                        }, 500)}
                                    />
                                </div>
                                <div className="travel-one__form-row">
                                    <Input label={t('driverDocSeries')}
                                        refLinlk={(el) => { itemsRef.current[`driver[${i}]docSeries`] = el }}
                                        name={`driver[${i}]docSeries`}
                                        error={errors[`driver[${i}]docSeries`]}
                                        placeholder="AA"
                                        divClass="input input__required auto__input"
                                    />
                                    <Input label={t('driverDocNumber')}
                                        name={`driver[${i}]docNumber`}
                                        refLinlk={(el) => { itemsRef.current[`driver[${i}]docNumber`] = el }}
                                        error={errors[`driver[${i}]docNumber`]}
                                        placeholder="1234567"
                                        divClass="input input__required auto__input"
                                    />
                                </div>
                                {
                                    (values.driver.length > 1) && (
                                        <a className="change__button auto__copy-button"
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                // eslint-disable-next-line no-shadow
                                                setFieldValue('driver', [...values.driver].filter((_, index) => index !== i))
                                                setShortValidate(true)
                                                debouceHandleSubmit()
                                            }}
                                        >
                                            {t('deleteDriver')}
                                        </a>
                                    )
                                }
                                <div className="driver-block__number">
                                    {i + 1}
                                </div>
                            </div>
                        ))
                    }
                    {
                        (values.multiDriveOption === 0 && values.driver.length < 3) && (
                            <a className="change__button auto__copy-button auto__add-button"
                                onClick={async (e) => {
                                    e.preventDefault()
                                    if (values.driver.length < 3) {
                                        setFieldValue('driver', [
                                            ...values.driver,
                                            {
                                                driverFullName: '',
                                                driverLastname: '',
                                                driverFirstname: '',
                                                driverPatr: '',
                                                docSeries: '',
                                                docNumber: '',
                                                docIssueDate: '',
                                                driverGender: '',
                                                driverBd: '',
                                                driverCitizenship: 'TJ',
                                            },
                                        ])

                                        setShortValidate(true)
                                        debouceHandleSubmit()
                                    }
                                }}
                            >
                                `{t('addDriver')}
                            </a>
                        )
                    }

                    <Checkbox
                        name="agree"
                        className="travel-order__text checkbox rules__check"
                        error={errors.agree}
                        refLinlk={(el) => { itemsRef.current.agree = el }}
                        label={(
                            <>
                                {t('common:agreement1')}&nbsp;
                                <a className="auto-insurance__rules-link"
                                    href="/rules"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement2')}
                                </a>
                                &nbsp;{t('common:agreement3')}&nbsp;
                                <a
                                    className="auto-insurance__rules-link"
                                    href="/politics"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('common:agreement4')}
                                </a>
                            </>
                        )} />
                    {
                        errors.haveErrors && (
                            <div className="warning-fields">
                                {t('reqFieldsHead')}&nbsp;
                                <button className="warning-fields__button" onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    window.scrollTo({
                                        top: itemsRef.current[errors.firstError].getBoundingClientRect().top + window.pageYOffset - 50,
                                        left: 0,
                                        behavior: 'smooth',
                                    })
                                }}>
                                    {t('reqFieldsText')}
                                </button>
                            </div>
                        )
                    }

                </div>

                <FormTotal />
            </div>
        </section>
    )
}
