import React, { useContext, useState } from 'react'
import cn from 'classnames'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import { useTranslation } from 'react-i18next'
import sprite from '../static/img/svg/svg-sprite.svg'
import { useModal } from '../hooks/modal'
import BackCall from './modals/BackCall'
import { AuthContext } from '../contexts/AuthContext'
import { useLoad } from '../hooks/request'
import { CMS } from '../utils/urls'
import Loader from './base/Loader'
import { Context } from '../contexts/GlobalContext'

const MapSite = ({ geometryPm }) => (
    <YMaps>
        <div>
            <Map className="innerMap"
                state={{ center: geometryPm || [38.5854118947702, 68.74819587301627], zoom: geometryPm ? 14 : 10 }}>
                <Placemark geometry={geometryPm} />
            </Map>
        </div>
    </YMaps>
)

export default function OfficesList() {
    const { language } = useContext(Context)
    const { cmsToken } = useContext(AuthContext)
    const { response: offices } = useLoad({ url: CMS.OFFICES }, [cmsToken, language])
    const { t } = useTranslation('offices')

    const [active, setActive] = useState(0)
    const [showBackCall] = useModal(
        <BackCall className="popup__policy-check" />,
    )

    const [geometryPm, setGeometryPm] = useState([38.5854118947702, 68.74819587301627])

    if (!offices) {
        return <Loader centered />
    }

    return (
        <div className="offices">
            <div className="offices__container">
                <h3 className="offices__title">
                    {t('officesSales')}
                </h3>
                <div className="offices__info">
                    <div className="offices__map">
                        <MapSite geometryPm={geometryPm} />
                    </div>
                    <div className="offices__items">
                        {
                            offices.map(({ title, address, coords }, i) => (
                                <div className={
                                    cn('offices__item', { offices__item_active: i === active })
                                } onClick={() => {
                                    setActive(i)
                                    setGeometryPm(coords.split(','))
                                }}>
                                    <p className="offices__subtitle">
                                        <svg width="20" height="20">
                                            <use xlinkHref={`${sprite}#icon-office`} />
                                        </svg>
                                        {title}
                                    </p>
                                    <div className="offices__address">
                                        {address}
                                    </div>
                                    <div className="offices__body">
                                        <button className="offices__button"
                                            onClick={() => {
                                                showBackCall({ note: title })
                                            }}
                                            type="button">
                                            {t('callRequest')}
                                        </button>
                                        <div className="offices__link"
                                            onClick={() => setGeometryPm(coords.split(','))}
                                        >
                                            {t('onMap')}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
